<template>
  <div>
    <div class="m-b-10">
      <h3>xxx客户（xxx品牌）<span class="m-l-5">2021-09-30</span></h3>
    </div>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="4">
        <Select
          v-model="query.assetId"
          size="small"
          placeholder="选择资产"
        >
          <option
            v-for="item in assetArray"
            :key="'assetId_'+item.assetId"
            :value="item.assetId"
          >{{item.assetName}}</option>
        </Select>
      </i-col>
      <i-col span="4">
        <Select
          v-model="query.stationId"
          size="small"
          placeholder="选择站点"
        >
          <option
            v-for="item in stationArray"
            :key="'stationId_'+item.id"
            :value="item.id"
          >{{item.name}}</option>
        </Select>
      </i-col>
      <i-col span="4">
        <Button
          size="small"
          type="primary"
          icon="ios-search"
          @click="handleSearch"
        >搜索</Button>
        <Button
          size="small"
          type="success"
          class="m-l-5"
          @click="handleDownload"
        >导出EXCEL</Button>
      </i-col>
    </Row>
    <div>
      <Table
        stripe
        size="small"
        :data="list"
        :columns="tableColumns"
      ></Table>
    </div>
  </div>
</template>

<script>
// import { sysMixins } from '@/assets/mixins/sys'
export default {
  // mixins: [sysMixins],
  data () {
    return {
      query: {
        assetId: null,
        stationId: null
      },
      assetArray: [],
      stationArray: [],
      list: [
        { startTime: '07:30:00', endTime: '07:30:15', filePath: '' },
        { startTime: '07:30:16', endTime: '07:30:46', filePath: '' },
        { startTime: '07:30:47', endTime: '07:31:02', filePath: '' },
        { startTime: '07:31:03', endTime: '07:31:33', filePath: '' },
        { startTime: '07:31:34', endTime: '07:31:49', filePath: '' },
        { startTime: '07:31:50', endTime: '07:32:05', filePath: '' },
        { startTime: '07:32:06', endTime: '07:32:36', filePath: '' },
        { startTime: '07:32:37', endTime: '07:32:52', filePath: '' }
      ],
      tableColumns: [
        {
          title: '播放时间',
          align: 'center',
          render: (h, params) => {
            return h('span', `${params.row.startTime} - ${params.row.endTime}`)
          }
        },
        {
          title: '播放素材',
          align: 'center',
          render: (h, params) => {
            return h('a', {
              on: {
                click: () => {

                }
              }
            }, '查看素材')
          }
        }
      ]
    }
  },
  created () {
    // // 设置头部路由标签
    // this.setLinkTagArray({ key: 'eleMediaPublishDetail', value: '刊播详情' })
    // this.setLinkRouterMaps(['eleMediaPublishDetail', () => { this.handlePage() }])
    // this.setActivedTagName('eleMediaPublishDetail')
  },
  methods: {
    // handlePage () {
    //   // this.setLeftComponent('ActionDateView')
    //   this.setShowLeftBlock(true)
    //   // this.setRightComponent('BasicInfo')
    //   this.setBottomComponent('Detail')
    // }
    iniData () {

    },
    handleSearch () {

    },
    handleDownload () {

    }
  }
}
</script>
